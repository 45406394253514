import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'assistant',
  formId: 'DK%20Piccoline',
  title: 'Kontorassistent',
  subTitle: 'Brug for et par ekstra hænder?',
  icon: '/icons/office_assistant.svg',
  image: '/assets/images/sunrise/office_assistant_supermercat.png',
  theme: 'sunrise-office_assistant',
  sections: [
    {
      sectionId: 'hero',
      label: 'Kontorassistent',
      title: 'Brug for et par ekstra hænder?',
      description:
        'De fleste steder er der brug for en, der rydder op efter møder, klarer opvaskemaskinen, løber ærinder, strømliner kontoret, når det er på vrangen, køber ind, gør lidt rent, vander blomster... Sygedage? Vi sender en anden.',
      image: '/assets/images/sunrise/office_assistant_supermercat.png',
      icon: '/icons/office-assistant.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Alle mand på dæk!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/office_assistant/service_1.svg',
          title: 'Dag til dag',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_2.svg',
          title: 'Fyld op',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_3.svg',
          title: 'Ærinder',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_4.svg',
          title: 'Frokost - før og efter',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Sådan får du besøg af en kontor-engel',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/office_assistant/journey_1.svg',
          title: 'Fortæl hvad du har brug for',
          description: '…så vi kan strikke den perfekte løsning sammen til dig',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_2.svg',
          title: 'Modtag et til bud',
          description: 'Detaljeret, men overskueligt. Ingen overraskelser.',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_3.svg',
          title: 'Vi mødes',
          description: 'Sammen skaber vi den bedste plan for jer.',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_4.svg',
          title: 'Ja tak!',
          description: 'Så er der ekstra hænder på vej til jer.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor vore kunder elsker kontor-engle fra Good Monday',
      description: '',
      image: '/assets/images/services/office_assistant/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-calendar-outline',
          title: 'De dukker altid op',
          description: 'Ferie og sygdom er vores hovedpine. Vi sender en anden.',
        },
        {
          icon: 'eva eva-pie-chart-outline',
          title: 'Slip for en ekstra fuldtidsansat',
          description: 'Du slipper for at bekymre dig om det ansættelsestekniske.',
        },
        {
          icon: 'eva eva-flash-outline',
          title: 'Klar med det samme',
          description: 'Vi står for oplæring, så I får hjælp fra dag 1.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at du med Good Mondays kontor-engle aldrig løber tør igen?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
